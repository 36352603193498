<template>
  <v-row>
    <v-col cols="12">
      <!-- COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit)-->
      <v-card
        v-if="parameterIfShowOrderLineCreditCard"
        color="primary"
        dark
      >
        <v-card-title class="text-h3">
          <v-icon
            class="mr-4"
            x-large
          >
            mdi-sim
          </v-icon>
          <span :class="contratType2 === parameterContratType2Max ? 'text-decoration-line-through' : ''">{{ $t('order.Order_cell_line').toUpperCase() }}</span>
          <span class="ml-2">{{ getContractNumber }}</span>
        </v-card-title>

        <v-spacer />

        <v-card-subtitle>
          <v-icon
            class="mr-4"
            x-large
          >
            mdi-credit-card
          </v-icon>
          <span :class="contratType2 >= parameterContratType2Max ? 'text-decoration-line-through' : ''">
            {{ $t('order.Payable_by_credit_card') }}
          </span>
          <span class="ml-2">({{ `${$t('order.Limit_client')} ${parameterContratType2Max} ${$t('order.Limit_client1')}` }})</span>
        </v-card-subtitle>

        <v-card-actions>
          <v-btn
            :disabled="contratType2 >= parameterContratType2Max"
            class="ml-2 mt-5"
            outlined
            rounded
            small
            @click="order(3)"
          >
            {{ $t('order.name') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- COMMANDER UNE LIGNE CELLULAIRE (Seulement pour les employé)-->
      <v-card
        v-if="parameterIfShowOrderLineUbr && client.type === '1'"
        color="green"
        dark
      >
        <v-card-title class="text-h3">
          <v-icon
            class="mr-4"
            x-large
          >
            mdi-sim
          </v-icon>
          {{ $t('order.Order_cell_line').toUpperCase() }}
        </v-card-title>

        <v-spacer />

        <v-card-subtitle>
          <v-icon
            class="mr-4"
            x-large
          >
            mdi-book-open-variant
          </v-icon>
          {{ $t('order.Payable_by_account') }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn
            class="ml-2 mt-5"
            outlined
            rounded
            small
            @click="order(1)"
          >
            {{ $t('order.name') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- COMMANDER UN TELEPHONE CELLULAIRE (Seulement pour les employé et les retraités professeur)-->
      <v-card
        v-if="parameterIfShowOrderDeviceUbr && (client.type === '1' || client.type === '4')"
        color="green"
        dark
      >
        <v-card-title class="text-h3">
          <v-icon
            class="mr-4"
            x-large
          >
            mdi-cellphone-nfc
          </v-icon>
          {{ $t('order.Order_cell_phone').toUpperCase() }}
        </v-card-title>

        <v-spacer />

        <v-card-subtitle>
          <v-icon
            class="mr-4"
            x-large
          >
            mdi-book-open-variant
          </v-icon>
          {{ $t('order.Payable_by_account') }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn
            class="ml-2 mt-5"
            outlined
            rounded
            small
            @click="order(2)"
          >
            {{ $t('order.name') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <!-- DIALOG-->
    <!-- COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)-->
    <v-dialog
      v-model="dialog3"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog3 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-credit-card
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_line') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_credit_card') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)-->
        <order-new-line-credit
          ref="orderNewPhoneCredit"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type1="parameterPickupDeliveryType1"
          :parameter-pickup-delivery-type2="parameterPickupDeliveryType2"
          :parameter-mode-paiement="parameterModePaiement"
          @submit="submit"
        />
      </v-card>
    </v-dialog>

    <!-- COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)-->
    <v-dialog
      v-model="dialog1"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog1 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-book-open-variant
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_line') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_account') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- Composant pour COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)-->
        <order-new-line-ubr
          ref="orderNewLineUbr"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type1="parameterPickupDeliveryType1"
          :parameter-pickup-delivery-type2="parameterPickupDeliveryType2"
          :parameter-longueur-code-facturation="parameterLongueurCodeFacturation"
          @submit="submit"
        />
      </v-card>
    </v-dialog>

    <!-- COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)-->
    <v-dialog
      v-model="dialog2"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog2 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-cellphone-nfc
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_phone') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_account') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)-->
        <order-new-phone-ubr
          ref="orderNewPhoneUbr"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type3="parameterPickupDeliveryType3"
          :parameter-longueur-code-facturation="parameterLongueurCodeFacturation"
          @submit="submit"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  // Component
  import OrderNewLineUbr from '@/components/Client/Order/components/OrderNewLineUbr'
  import OrderNewPhoneUbr from '@/components/Client/Order/components/OrderNewPhoneUbr'
  import OrderNewLineCredit from '@/components/Client/Order/components/OrderNewLineCredit'
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  // import MessagesService from '@/services/01Cell/MessagesService'
  // Other
  import { EventBus } from '@/utils/event-bus'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Order',
    components: {
      OrderNewLineUbr,
      OrderNewPhoneUbr,
      OrderNewLineCredit,
    },
    props: {
      client: Object,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        state: 'create',
        dialog1: false,
        dialog2: false,
        dialog3: false,
        contratType2: 0,
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterContratType2Max: 'getContratType2Max',
        parameterPickupDeliveryType1: 'getPickupDeliveryType1',
        parameterPickupDeliveryType2: 'getPickupDeliveryType2',
        parameterPickupDeliveryType3: 'getPickupDeliveryType3',
        parameterModePaiement: 'getModePaiement',
        parameterLongueurCodeFacturation: 'getLongueurCodeFacturation',
        parameterIfShowOrderLineCreditCard: 'ifShowOrderLineCreditCard',
        parameterIfShowOrderLineUbr: 'ifShowOrderLineUbr',
        parameterIfShowOrderDeviceUbr: 'ifShowOrderDeviceUbr',
        parameterUBRValidationEmail: 'getOrderUBRValidationEmail',
        parameterLanguage: 'getLanguage',
      }),
      getContractNumber () {
        if (this.contratType2 === 1) return `(${this.contratType2} ${this.$i18n.t('contract.Active_contract')})`
        if (this.contratType2 > 1) return `(${this.contratType2} ${this.$i18n.t('contract.Active_contracts')})`
        return `(${this.$i18n.t('contract.No_contract')} ${this.$i18n.t('Active').toLowerCase()})`
      },
    },

    methods: {
      updateOrder (client) {
        // Pour savoir si l'utilisateur à atteint le maximun de contrat par carte de crédit
        const commandesType2 = client.Commandes.filter(item => item.type < 3 && item.mode_paiement === 2 && item.statut < 4 && item.statut > 0).length
        const contratType2 = client.Contrats.filter(x => x.type === 2 && x.statut !== 2 && x.statut !== 4 && x.statut !== 6).length
        this.contratType2 = commandesType2 + contratType2
      },
      order (orderType) {
        if (orderType === 1) {
          this.dialog1 = true
          // Reset de la variable 'submit'
          this.$nextTick(() => {
            this.$refs.orderNewLineUbr.resetData()
          })
        }
        if (orderType === 2) {
          this.dialog2 = true
          // Reset de la variable 'submit'
          this.$nextTick(() => {
            this.$refs.orderNewPhoneUbr.resetData()
          })
        }
        if (orderType === 3) {
          this.dialog3 = true
          // Reset de la variable 'submit'
          this.$nextTick(() => {
            this.$refs.orderNewPhoneCredit.resetData()
          })
        }
      },
      closeDialog () {
        this.dialog1 = false
        this.dialog2 = false
        this.dialog3 = false
      },
      // Fonction appellé des composants des dialogs lorsqu'un utilisateur soumets une commande
      async submit (submitForm, message) {
        // Close Dialog
        this.closeDialog()
        // Start Spinner
        this.$loading.show()
        try {
          await CommandesService.save(submitForm)

          // Ouvre un dialog pour informer le client
          this.$dialog.show({ message: message, print: false, html: true, iconState: true, iconColor: 'success', iconName: 'mdi-check-circle' })
          // On update le client. Le spinner va arrête dans l'autre composant
          EventBus.$emit('update-order')
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
    },
  }
</script>
